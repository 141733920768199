import { Trans } from 'react-i18next';

import { TextField, type TextFieldProps } from '~/features/form/field/form-field/text-field';

export const Username = ({
  name = 'userName',
  type = 'text',
  autoComplete = 'username',
  label = (
    <Trans ns='field' i18nKey='username'>
      Username
    </Trans>
  ),
  ...props
}: Partial<TextFieldProps>) => (
  <TextField
    name={name}
    label={label}
    type={type}
    // icon={<FaUser/>}
    autoComplete={autoComplete}
    {...props}
  />
);
